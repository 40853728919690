import Layout from "components/Layout";
import React, { useEffect, useState } from "react";
import { getCookie } from "utils/helper";
import { useFetchers, useLocation } from "react-router-dom";
import { chatAuth, socketUrl } from "config/url";
import { LoadingOutlined } from "@ant-design/icons";

const Index = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const matchedId = location?.state?.matchedId;
    const script = document.createElement("script");
//    script.src = "/botLoaderScript.js";
	script.src = "https://cdn.jsdelivr.net/gh/NishantThapaOB/CDN-testing@main/botLoaderScript.js";
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    script.defer = true;
    script.type = "text/javascript";
    script.setAttribute("userId", matchedId);
    script.setAttribute("token", getCookie("cl_a"));
    script.setAttribute("height", "90vh");
    script.setAttribute("width", "100vw");
    script.setAttribute("chatUrl", chatAuth);
    script.setAttribute("socketUrl", socketUrl);
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
      const e = document.getElementById("iframeWidget-CHAT");
      if (e) {
        document.body.removeChild(e);
      }
    };
  }, []);

  const location = useLocation();
  return (
    <Layout>
      {loading && (
        <div className="flex justify-center mt-4">
          <LoadingOutlined />
          Loading...
        </div>
      )}
    </Layout>
  );
};

export default Index;
